import React from 'react'
import { Link } from './link'
import Linkedin from '../images/icons/linkedin-in-brands.svg'
import Facebook from '../images/icons/facebook-f-brands.svg'
import Youtube from '../images/icons/youtube.svg'
import Twitter from '../images/icons/twitter-brands.svg'
import PhoneIcon from '../images/icons/phone-solid.svg'
import MarkerIcon from '../images/icons/map-marker-alt.svg'
import EnvelopeIcon from '../images/icons/envelope-solid.svg'

import AriadneGroup from "../images/icons/ariadne-group-logo.svg";

const Footer = () => (
  <>
  <div className="footer-group-top">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          Ariadne eLearning è parte di <strong>Ariadne Group</strong>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-group">
    <a
      href="https://www.ariadnegroup.it"
      title="Ariadne Group - the Digital Compass"
      className="footer-group-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="invisible">Vai al sito Ariadne Group</span>
    </a>
    <div className="container">
      <div className="row">
        <div className="col-md-4 d-flex justify-content-center justify-content-md-start align-items-md-center">
          <AriadneGroup className="ariadne-group-logo" />
        </div>
        <div className="col-md-4 d-flex justify-content-center align-items-md-center my-4 my-md-0">
          <div>
            the <strong className="text-uppercase">Digital</strong>{" "}
            <span className="text-uppercase">Compass</span>
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-center justify-content-md-end align-items-md-center">
          <span className="footer-group__btn">vai al sito</span>
        </div>
      </div>
    </div>
  </div>
  <div className="footer">
    <div className="container">
      <div className="row mt-2 pt-3">
        <div className="col-md-6 col-lg-4">
          <div className="footer__head">Sede legale</div>
          <p>
            Via Visconti di Modrone, 11 - 20122 Milano<br />
            Ariadne eLearning S.r.l. a socio unico<br />
            C.F. e Registro Imprese: 11659010968<br />
            Partita IVA: 11659010968<br />
            Iscritta al Registro Imprese di Milano<br />
            REA: MI-2617350 - Cap. Soc. 25.000,00 € i. v.
          </p>
        </div>
        <div className="col-md-6 col-lg-4 mt-3 mt-md-0">
          <div className="footer__head">Uffici</div>
          <div className="footer__contatti">
            <div className="d-inline-flex">
              <span className="ico-svg mr-2"><MarkerIcon /></span>
              <p className="mt-1 mb-2 pb-1">
                Trivolzio (PV)<br />
                Via Giovanni Perotti 4, 27020
              </p>
            </div>
            <div className="d-inline-flex">
              <span className="ico-svg mr-2"><MarkerIcon /></span>
              <p className="mt-1 mb-2 pb-1">
                Avellino (AV)<br />
                Via Giuseppe Zigarelli 43, 83100<br />
              </p>
            </div>
            <br />
            <a id="conv-tracking-tel" title="telefono" href="tel:+390382408911" className="mb-2">
              <span className="ico-svg mr-2"><PhoneIcon /></span> +39 0382 408911
            </a>
            <br />
            <a id="conv-tracking-mail" title="email" href="mailto:info@ariadnelearning.it">
              <span className="ico-svg mr-2"><EnvelopeIcon /></span> info@ariadnelearning.it
            </a>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0">
          <div className="footer__head">Vuoi maggiori informazioni?</div>
          <Link to="/contatti/" className="btn-link">Contattaci</Link>
          <p className="footer__social mt-3 d-inline-block align-self-lg-end">
            <a href="https://www.linkedin.com/company/ariadnegroup/" title="Linkedin" className="footer__link"
              target="_blank" rel="noopener noreferrer">
              <span className="ico-svg"><Linkedin /></span><span className="hidden-xs-down"></span>
            </a>
            <a href="https://www.facebook.com/AriadneGroup" title="Facebook" className="footer__link"
              target="_blank" rel="noopener noreferrer">
              <span className="ico-svg"><Facebook /></span><span className="hidden-xs-down"></span>
            </a>
            <a href="https://twitter.com/AriadneGroup" title="Twitter" className="footer__link"
              target="_blank" rel="noopener noreferrer">
              <span className="ico-svg"><Twitter /></span><span className="hidden-xs-down"></span>
            </a>
            <a href="https://www.youtube.com/channel/UC7_Z0F7wvMmzuYxa0OAq-Zg" title="Youtube" className="footer__link"
              target="_blank" rel="noopener noreferrer">
              <span className="ico-svg"><Youtube /></span><span className="hidden-xs-down"></span>
            </a>
          </p>
          <p className="footer__policy ml-lg-auto">
            {/* <Link to="/privacy/">Cookie policy</Link>
            <span> | </span> */}
            <Link to="/privacy/">Privacy policy</Link>
          </p>
        </div>
      </div>
    </div>
  </div>
  </>
);

export default Footer
